<script setup lang="ts" generic="T">
import type { UISliderWithArrowsProps } from './UISliderWithArrows.props'
import { Navigation, A11y, Keyboard } from 'swiper/modules'

const props = withDefaults(defineProps<UISliderWithArrowsProps<T>>(), {
  showNavigation: true,
  staticOnDesktop: false,
  loop: true,
  hasNoSpaceBetween: false,
})

const visibleNavigationPanel = ref<null | 'L' | 'R'>(null)

const sliderContainer = ref<HTMLElement>()
const { width } = useElementSize(sliderContainer)

const mousemoveHandler = (e: MouseEvent) => {
  const currentTarget = <HTMLTextAreaElement>e.currentTarget
  const x = e.pageX - (currentTarget.offsetLeft ?? 0)

  if (x < width.value / 2) {
    visibleNavigationPanel.value = 'L'
  } else {
    visibleNavigationPanel.value = 'R'
  }
}

const items = computed(() => {
  if (!props.loop) return props.items

  const maxSlidesPerView = props.staticOnDesktop ? 3 : 5
  let _tempArray = props.items

  while (_tempArray.length < maxSlidesPerView * 2) {
    _tempArray = _tempArray.concat(_tempArray)
  }
  return _tempArray
})
</script>
<template>
  <div v-if="items && items.length" ref="sliderContainer">
    <Swiper
      slides-per-view="auto"
      :modules="[Navigation, A11y, Keyboard]"
      keyboard
      :space-between="hasNoSpaceBetween ? 0 : 1"
      :navigation="
        showNavigation && {
          enabled: true,
          nextEl: '.swiper-related-button-next',
          prevEl: '.swiper-related-button-prev',
        }
      "
      :loop="loop"
      grab-cursor
      :initial-slide="loop ? 1 : 0"
      @mousemove="(e: any) => mousemoveHandler(e as MouseEvent)"
      @mouseleave="visibleNavigationPanel = null"
    >
      <SwiperSlide
        v-for="(item, index) in items"
        :key="`item__${index}`"
        :class="[
          slideCustomWidth ||
            '!w-full max-w-[240px] sm:max-w-[315px] md:!w-1/4 md:min-w-[315px] md:max-w-[none] lg:min-w-0',
          !slideCustomWidth
            ? staticOnDesktop
              ? 'lg:!w-1/3 lg:!min-w-0'
              : hasNoSpaceBetween
                ? 'lg:!w-1/4'
                : 'lg:!w-1/5'
            : '',
        ]"
      >
        <div class="h-full w-full">
          <slot v-bind="item" />
        </div>
      </SwiperSlide>
      <!-- eslint-disable-next-line -->
      <template v-if="showNavigation" #container-start>
        <button
          v-show="visibleNavigationPanel === 'L'"
          aria-label="prev"
          class="swiper-related-button-prev bg-neutral-white absolute -left-[2px] z-10 flex -translate-x-full cursor-pointer items-center justify-center"
          :class="[
            hasSlideVerticalBorder
              ? `top-[1px] h-[calc(100%_-_2px)]`
              : 'top-0 h-full',
          ]"
        >
          <DefaultIconsChevronLeft aria-hidden="true" />
        </button>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-if="showNavigation" #container-end>
        <button
          v-show="visibleNavigationPanel === 'R'"
          aria-label="next"
          class="swiper-related-button-next bg-neutral-white absolute top-0 z-10 flex translate-x-full cursor-pointer items-center justify-center"
          :class="[
            hasSlideVerticalBorder
              ? `top-[1px] h-[calc(100%_-_2px)]`
              : 'top-0 h-full',
            hasNoSpaceBetween
              ? '-right-[1px]'
              : slideCustomWidth || staticOnDesktop
                ? '-right-[3px]'
                : '-right-[5px]',
          ]"
        >
          <DefaultIconsChevronRight aria-hidden="true" />
        </button>
      </template>
    </Swiper>
  </div>
</template>

<style scoped lang="scss">
.swiper-related-button-prev,
.swiper-related-button-next {
  display: none;
  width: var(--padding-x);

  @screen md {
    display: flex;
  }
}
</style>
